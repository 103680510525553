import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// @material-ui/icons

// React icons
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardFooter from "components/Card/CardFooter.jsx"

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx"

import team1 from "assets/img/faces/avatar.jpg"
import team2 from "assets/img/faces/christian.jpg"
import team3 from "assets/img/faces/laurie.png"

class TeamSection extends React.Component {
  render() {
    const { classes } = this.props
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid,
    )
    return (
      <div className={classes.section} id="about">
        <h2 className={classes.title}>À propos</h2>
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <Card plain>
                <GridItem xs={12} sm={12} md={3} className={classes.itemGrid}>
                  <img src={team3} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Laurie El Moukhtari
                  <br />
                  <small className={classes.smallTitle}>Décoratrice d'interieur</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    Je suis persuadée qu’une pièce détermine notre humeur et augmente nos capacités, il faut donc que
                    votre intérieur vous ressemble et soit agréable pour que vous puissiez vous
                    épanouir ! Lors de mes études en Histoire et en Histoire de l’art j’ai pu m’ouvrir à plusieurs
                    courants qui m’inspirent aujourd’hui, puis lors de ma certification en décoration d’intérieur, je
                    n’ai eu comme unique but que de rendre la vie plus belle grâce à nos intérieurs en alliant passé et
                    modernité.
                  </p>
                </CardBody>
                <CardFooter className={classes.justifyCenter}>
                  <Button
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaInstagram />
                  </Button>
                  <Button
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaLinkedin />
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    )
  }
}

export default withStyles(teamStyle)(TeamSection)
